import { trpc } from '@/trpc.ts';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { normalizeStatistics } from '@/feature/call-statistics/call-statistics.utils.ts';
import { normalizeTransferTo } from '@/feature/chat/chat.utils.ts';
import { AnswerType, useCallFilters } from '../call-filters/call-filters';
import { createStateContainer } from '@/components/data-container';
import { useChatsUpdate } from '@/hooks/use-chats-update';
import { useQueryClient } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { StatisticsItem } from './call-statistics.types';
import { CallStatisticsTransferItem } from './call-statistics-transfer-item';
import { CallStatisticsTypeItem } from './call-statistics-type-item';

const Container = createStateContainer('Call statistics');

export const mergeStatistics = (newData: StatisticsItem[], oldData: StatisticsItem[]) => {
  const state: Record<string, StatisticsItem> = {};

  for (const item of [...newData, ...oldData]) {
    const key = `${item.type}-${item.transferTo}-${item.transferToDepartment}-${item.transferToFirstName}-${item.transferToLastName}`;

    state[key] = state[key] || { ...item, count: 0 };
    state[key].count += item.count;
  }

  return Object.values(state);
};

export const CallStatistics = () => {
  const { filters } = useCallFilters();

  const { data, isLoading, error } = trpc.getStatistics.useQuery(filters);

  const queryClient = useQueryClient();
  const queryKey = getQueryKey(trpc.getStatistics, filters, 'query');

  useChatsUpdate(
    (update) => {
      if (!update.statistics.length) return;

      queryClient.setQueryData(queryKey, (oldData: StatisticsItem[]) => {
        return mergeStatistics(update.statistics, oldData);
      });
    },
    [queryClient, queryKey],
  );

  const { transferredTo, totalTransferredTo, answeredByReceptionCount, transferredToLiveAgentCount, loadUpdatesCount } =
    normalizeStatistics(data);

  if (error) {
    return <Container.Error />;
  }

  if (isLoading) {
    return <Container.Loading />;
  }

  if (!data) {
    return <Container.NoData />;
  }

  return (
    <Container>
      <div className="mt-4 grid gap-3 lg:grid-cols-2">
        <div>
          <div className="grid gap-3 lg:grid-cols-2">
            <CallStatisticsTypeItem
              type={AnswerType.CONVERSATION_END}
              title="Answered by receptionist"
              count={answeredByReceptionCount}
              description="Answered and finished (conversation end)"
            />

            <CallStatisticsTypeItem
              type={AnswerType.TRANSFER_TO_AGENT}
              title="Transferred to live agent"
              count={transferredToLiveAgentCount}
            />

            <CallStatisticsTypeItem
              type={AnswerType.LOAD_UPDATES}
              title="Load updates"
              count={loadUpdatesCount}
              description="User asked for load updates"
            />
          </div>
        </div>

        <div>
          <Card>
            <CardHeader className="space-y-0 pb-0">
              <CardTitle className="text-sm font-medium">
                Total calls transferred to employees/departments: {totalTransferredTo}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="mt-4 grid max-h-[310px] gap-3 overflow-y-auto pb-2 lg:grid-cols-3">
                {transferredTo?.map(
                  ({ count, transferTo, transferToFirstName, transferToLastName, transferToDepartment }) => {
                    const title = `${transferToFirstName} ${transferToLastName}${transferToDepartment ? `, ${transferToDepartment}` : ''}`;
                    const toNormalizedTransferTo = normalizeTransferTo(transferTo);

                    return (
                      <CallStatisticsTransferItem
                        key={`${transferTo}--${transferToFirstName}--${transferToLastName}--${transferToDepartment}`}
                        transferTo={transferTo}
                        title={title}
                        count={count}
                        description={toNormalizedTransferTo}
                      />
                    );
                  },
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </Container>
  );
};
