import { FC } from 'react';
import { CallStatisticsItem, CallStatisticsItemProps } from './call-statistics-item';
import { AnswerType, useCallFilters } from '../call-filters/call-filters';

type Props = CallStatisticsItemProps & {
  transferTo?: string | null;
};

export const CallStatisticsTransferItem: FC<Props> = ({ transferTo, ...props }) => {
  const setPartialFilters = useCallFilters((state) => state.setPartialFilters);

  return (
    <CallStatisticsItem
      onClick={() => {
        if (!transferTo) return;

        setPartialFilters({ transferTo, messageTypes: [AnswerType.TRANSFER] });
      }}
      {...props}
    />
  );
};
