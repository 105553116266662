import { Statistics } from '@/feature/call-statistics/call-statistics.types.ts';

export const normalizeStatistics = (
  statistics?: Statistics,
): {
  totalCals?: number;
  transferredTo?: Statistics;
  totalTransferredTo?: number;
  answeredByReceptionCount?: number;
  transferredToLiveAgentCount?: number;
  loadUpdatesCount?: number;
} => {
  if (!statistics) {
    return {};
  }

  const answeredByReceptionCount = statistics.find((stat) => stat.type === 'CONVERSATION_END')?.count;
  const transferredToLiveAgentCount = statistics.find((stat) => stat.type === 'TRANSFER_TO_AGENT')?.count;
  const loadUpdatesCount = statistics.find((stat) => stat.type === 'LOAD_UPDATES')?.count;

  const transferredTo = statistics?.filter((stat) => stat.type === 'TRANSFER');
  const normalizedTransferredTo = transferredTo.sort((a, b) => b.count - a.count);

  const totalTransferredTo = transferredTo?.reduce((acc, { count }) => acc + count, 0);

  return {
    answeredByReceptionCount,
    transferredToLiveAgentCount,
    loadUpdatesCount,
    transferredTo: normalizedTransferredTo,
    totalTransferredTo,
  };
};
