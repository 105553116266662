import { FC } from 'react';
import { AnswerType, useCallFilters } from '../call-filters/call-filters';
import { CallStatisticsItem, CallStatisticsItemProps } from './call-statistics-item';

type Props = CallStatisticsItemProps & {
  type?: string | null;
};

export const CallStatisticsTypeItem: FC<Props> = ({ type, ...props }) => {
  const setPartialFilters = useCallFilters((state) => state.setPartialFilters);

  return (
    <CallStatisticsItem
      onClick={() => {
        if (!type) return;

        setPartialFilters({ messageTypes: [type as AnswerType], transferTo: undefined });
      }}
      {...props}
    />
  );
};
