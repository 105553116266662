import { FC } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

export type CallStatisticsItemProps = {
  title: string;
  count?: number;
  description?: string | null;
  onClick?: () => void;
};

export const CallStatisticsItem: FC<CallStatisticsItemProps> = ({ title, count = 0, description, onClick }) => {
  return (
    <Card onClick={onClick} className="cursor-pointer">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{count}</div>
        {description ? <p className="text-xs text-muted-foreground">{description}</p> : null}
      </CardContent>
    </Card>
  );
};
